exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:300);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Noto+Sans);", ""]);

// module
exports.push([module.id, "body, html {\n  font-family: 'Lato', sans-serif;\n  font-weight: 300;\n  margin: 0; }\n\nh1 {\n  font-size: 48px;\n  font-weight: 300; }\n\n.regular-weight {\n  font-weight: 400; }\n\n.root {\n  width: 100vw; }\n", ""]);

// exports
